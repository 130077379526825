@import "../../styles/breakpoints";
$border-radius: 16px;

.portfolio-item {
  display: flex;
  flex-direction: column;
  margin: 0.5rem 0;
  border-radius: $border-radius;
  transition: box-shadow 0.25s;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12),
    0 2px 1px -2px rgba(0, 0, 0, 0.2);
  width: 12rem;
  margin: 1rem 1rem;

  @include breakpoint-small-down {
    width: 15rem;
  }

  &:hover {
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.2);
  }

  &__title {
    padding: 1rem 1.5rem;
    font-size: 1.125rem;
    font-weight: 400;
    text-align: center;

    @include breakpoint-small-down {
      font-size: 1.25rem;
    }
  }

  &__desc {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 0 0.5rem;
    font-size: 0.75rem;
    font-weight: 400;
    text-align: center;
    line-height: 1.25rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;

    @include breakpoint-small-down {
      font-size: 0.875rem;
    }
  }

  &__icon {
    padding: 1rem 0;
    font-size: 1.5rem;
    font-weight: 100;
    text-align: center;

    svg {
      margin: 0 0.25rem;
    }
  }

  &__links {
    padding: 0 0 0.75rem;
    display: flex;
    justify-content: space-around;
    font-size: 0.875rem;
    font-weight: 400;

    a {
      padding-bottom: 0.25rem;
      border-bottom: 2px solid transparent;

      &:hover {
        cursor: pointer;
        border-bottom: 2px solid inherit;
      }
    }
  }
}
