.scroll-top {
  position: fixed;
  bottom: 0;
  right: 0;
  cursor: pointer;

  .arrow {
    text-align: center;
    margin: 0 auto;
    padding: 0.5rem 1rem;

    .to-top {
      text-align: center;
      font-size: 0.5rem;
      padding-top: 0.125rem;
    }
  }

  .hide {
    display: none;
  }
}
