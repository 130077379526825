@import "../../styles/breakpoints";

nav {
  position: fixed;
  top: 10px;
  width: 100%;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @include breakpoint-small-down {
    height: 4rem;
  }

  .magic-wand {
    cursor: pointer;
    justify-self: flex-start;
    margin-left: 1.3rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    .magic-text {
      text-align: center;
      font-size: 0.5rem;
      padding-top: 0.25rem;
    }

    &.bounce-xy {
      animation: bounce-xy 2s infinite;
    }

    @keyframes bounce-xy {
      0%,
      20%,
      50%,
      80%,
      100% {
        transform: translateY(0) translateX(0);
      }
      40% {
        transform: translateY(2px) translateX(2px);
      }
      60% {
        transform: translateY(1px) translateX(1px);
      }
    }
  }

  .menu {
    margin-right: 1rem;
    display: flex;
    flex: 1;
    justify-content: flex-end;

    &__item {
      padding: 0.25rem 0;
      margin: 0 1rem;
      font-size: 1.25rem;
      font-weight: 400;
      border-bottom: 2px solid transparent;

      &:hover {
        cursor: pointer;
      }
    }
  }
}
