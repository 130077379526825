@import "../../styles/breakpoints";
$border-radius: 16px;

.pastexperience-item {
  display: flex;
  flex-direction: column;
  margin: 1rem 1rem 1rem 0;
  align-items: flex-start;

  &__company {
    font-weight: 500;
  }

  &__jobDescription {
    display: flex;
    flex-direction: row;
    padding: 0;
    font-weight: 400;
    text-align: center;
    line-height: 1.25rem;
    align-items: flex-end;

    @include breakpoint-small-down {
      font-size: 0.875rem;
      line-height: 0.875rem;
    }
  }

  &__jobTitle {
    flex: 1;
    padding: 1rem 0rem 0.5rem 0rem;
    font-size: 1.025rem;
    font-weight: 600;
    text-align: center;

    @include breakpoint-small-down {
      font-size: 1.25rem;
    }
  }

  &__seperator {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    font-size: 2rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &__jobDuration {
    font-size: 0.75rem;
  }

  &__links {
    display: flex;
    justify-content: space-around;
    font-size: 0.875rem;
    font-weight: 400;

    a {
      padding-bottom: 0.25rem;
      border-bottom: 2px solid transparent;

      &:hover {
        cursor: pointer;
        border-bottom: 2px solid inherit;
      }
    }
  }

  &__tasksWrapper {
  }

  &__tasksDone {
    flex-flow: column;
    margin-top: 0.3rem;
  }

  &__listItem {
    display: list-item;
    align-items: center;
    justify-content: flex-start;
    list-style-type: disc;

    @include breakpoint-small-down {
      margin-top: 10px;
    }
  }

  &__listItemDot {
    height: 5px;
    width: 5px;
    border-radius: 50%;
    display: inline-block;
    align-items: center;
    margin-right: 10px;
  }
}
