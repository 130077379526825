@import "../../styles/breakpoints";

.experience-page {
  padding-top: 2rem;
  min-height: 100vh;
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;
  justify-content: center;

  @include breakpoint-small-down {
    padding-top: 3rem;
  }

  h1 {
    padding: 1rem 0;
    font-size: 3rem;
  }

  .content-grid {
    flex: auto;
  }

  .experience-wrapper {
    .experience-content {
      font-size: 0.875rem;
      line-height: 1.5rem;

      @include breakpoint-small-down {
        font-size: 1rem;
      }

      p {
        font-weight: 300;
        padding: 0.5rem 0;
      }

      .text-emoji {
        padding: 2rem 0;
        font-size: 1.5rem;
        font-weight: 300;
        text-align: center;
      }
    }
  }
}
